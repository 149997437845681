<template>
  <div class="button-submit-searcher">
    <b-button
      :variant="searched ? 'outline-blue' : 'blue-button'"
      :class="searched ? 'outline-blue' : 'blue-button'"
      @click="sendFilters()"
      :disabled="total_results < 1"
    >
      <div class="d-flex justify-content-center align-items-center">
        <b-spinner v-if="loading" small type="grow" class="mr-1"></b-spinner>
        <p class="mb-0">{{text}}</p>
      </div>
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BSpinner
} from 'bootstrap-vue'

export default {
  name: 'ButtonSubmitFilters',
  props: {
    searched: {
      type: Boolean,
      default: true
    },
    total_results: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    }
  },
  components: {
    BButton,
    BSpinner
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    sendFilters() {
      this.$emit('sendFilters')
      this.$emit('closeSideBar')
    }
  },
}
</script>
