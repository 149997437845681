<template>
  <b-row class="match-height">
    <b-col cols="12">
      <leaflet-basic v-if="show"/>
      <b-card>
        <l-map
          :zoom.sync="zoom"
          :options="mapOptions"
          :bounds="bounds"
          style="height: 500px; width: 100%"
          ref="map"
        >
          <l-tile-layer :url="url" />

          <l-marker
            v-for="marker in locations"
            :key="marker.id"
            :visible="true"
            :draggable="false"
            :lat-lng.sync="marker.position"
          >
            <l-popup :content="marker.tooltip" />
            <l-tooltip :content="marker.tooltip" />
          </l-marker>
          <l-control-zoom :position="zoomPosition" />
        </l-map>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import LeafletBasic from '@/views/charts-and-maps/maps/leaflet/LeafletBasic.vue'

import { latLngBounds, Icon } from 'leaflet'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

import {
  LMap,
  LTileLayer,
  LControlZoom,
  LMarker,
  LTooltip,
  LPopup,
} from 'vue2-leaflet'

export default {
  props: {
    locations: {
      type: Array,
      default: () => [],
    },
    marker: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    LMap,
    LTileLayer,
    LeafletBasic,
    LControlZoom,
    LMarker,
    LTooltip,
    LPopup,
  },
  data() {
    return {
      zoom: 8,
      show: false,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      mapOptions: {
        zoomControl: false,
        attributionControl: false,
        zoomSnap: true,
      },
      zoomPosition: 'topleft',
      bounds: latLngBounds(
        { lat: 14.1303191397, lng: -117.0556529104 },
        { lat: 32.5803068469, lng: -86.030154997 }
      ),
    }
  },
  created() {
    console.log(this.marker, 'Aque')
    // TODO: pintar bien el mapa y hacer zoom al marker
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
</style>
